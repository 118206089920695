<template>
  <div class="container-fluid">
    <b-row>
      <b-col
        :cols="$store.getters['layout/getInnerWidth'] >= 991 ? '6' : '12'"
      >
        <b-col class="timezone-form-title" cols="12">
          {{ $t('components.custom.playground.new.step-2.timezone.informations') }}
        </b-col>
        <b-col cols="12">
          <b-row class="mt-2">
            <b-col class="niche-field-name-class" cols="6">
              {{ $t('components.custom.playground.new.step-2.timezone.from') }}
            </b-col>
            <b-col class="niche-field-name-class" cols="6">
              {{ $t('components.custom.playground.new.step-2.timezone.until') }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="niche-field-name-class" cols="6">
              <vue-timepicker
                v-model="block.startAt"
                @change="startAtvalid = true"
                input-class="background-light-blue-inputs"
              >
                <template v-slot:icon>
                  <i class="clock-class fa fa-clock-o"></i>
                </template>
                <template v-slot:clearButton>
                  <span class="fa fa-close"></span>
                </template>
              </vue-timepicker>
              <div
                v-if="!startAtvalid"
                class="invalid-feedback d-block"
              >
                {{ $t('validation.required') }}
              </div>
            </b-col>
            <b-col class="niche-field-name-class" cols="6">
              <vue-timepicker
                v-model="block.endAt"
                input-class="background-light-blue-inputs"
                @change="endAtvalid = true; endAtvalidGreater = true;"
              >
                <template v-slot:icon>
                  <i class="clock-class fa fa-clock-o"></i>
                </template>
                <template v-slot:clearButton>
                  <span class="fa fa-close"></span>
                </template>
              </vue-timepicker>
              <div
                v-if="!endAtvalid"
                class="invalid-feedback d-block"
              >
                {{ $t('validation.required') }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col
              :cols="hasBookingMultipleType ? 6 : 12"
              class="mt-1 niche-field-name-class"
            >
              {{ $t('components.form.block.name.title') }}
            </b-col>
            <b-col
              v-if="hasBookingMultipleType"
              class="mt-1 niche-field-name-class"
              cols="6"
            >
              {{ $t('components.form.block.max-participants-count-limit.title') }}
            </b-col>
          </b-row>
          <validation-observer
            ref="nameObserver"
            slim
          >
            <b-row>
              <b-col
                class="mt-1"
                :cols="hasBookingMultipleType ? 6 : 12"
              >

                <d-text-field
                  v-model="block.name"
                  :rules="validation.name"
                  place-holder="components.form.block.name.placeholder"
                  class-name="background-light-blue-inputs"
                  size="sm"
                  error-text="validation.required"
                />
              </b-col>
              <b-col
                v-if="hasBookingMultipleType"
                class="mt-1"
                cols="6"
              >
                <d-text-field
                  v-model="block.maxParticipantsCountLimit"
                  min="1"
                  size="sm"
                  type="number"
                  place-holder="components.form.block.max-participants-count-limit.placeholder"
                  class-name="background-light-blue-inputs"
                  error-text="validation.required"
                />
                <div
                  v-if="parseInt(block.maxParticipantsCountLimit) === 0"
                  class="invalid-feedback d-block"
                >
                  {{ $t('components.form.block.max-participants-count-limit.not-zero') }}
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
        <b-col
          class="timezone-form-title mt-3"
          cols="12"
        >
          {{ $t('components.custom.playground.new.step-2.timezone.step-position') }}
        </b-col>
        <b-col cols="12">
          <b-row class="mt-2">
            <b-col
              class="niche-field-name-class"
              cols="6"
            >
              {{ $t('components.custom.playground.new.step-2.timezone.management') }}
            </b-col>
            <b-col class="niche-field-name-class" cols="6">
              {{ $t('components.custom.playground.new.step-2.timezone.application') }}
            </b-col>
          </b-row>
        </b-col>
        <b-col class="mt-1" cols="12">
          <b-row>
            <b-col cols="6">
              <select
                v-model="block.userClubStepBookingDuration"
                size="sm"
                class="form-control background-light-blue-inputs"
              >
                <template v-for="duration of userClubStepBookingDuration">
                  <option
                    :key="duration"
                    :value="duration"
                  >
                    {{ duration }} {{ $t('general.actions.time.minutes') }}
                  </option>
                </template>
              </select>
            </b-col>
            <b-col cols="6">
              <select
                v-model="block.userClientStepBookingDuration"
                size="sm"
                class="form-control background-light-blue-inputs"
              >
                <template v-for="duration of userClientStepBookingDuration">
                  <option
                    :key="duration"
                    :value="duration"
                  >
                    {{ duration }} {{ $t('general.actions.time.minutes') }}
                  </option>
                </template>
              </select>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
      <b-col
        :class="$store.getters['layout/getInnerWidth'] >= 991 ? '' : 'mt-3'"
        :cols="$store.getters['layout/getInnerWidth'] >= 991 ? '6' : '12'"
      >
        <b-row>
          <b-col class="timezone-form-title" cols="12">
            {{ $t('components.custom.playground.new.step-2.timezone.payment-mode') }}
          </b-col>

          <b-col class="niche-field-name-class mt-2" cols="12">
            {{ $t('components.custom.playground.new.step-2.timezone.payment-description') }}
          </b-col>

          <b-col class="mt-2" cols="12">
            <b-row :class="$store.getters['layout/getInnerWidth'] >= 991 ? 'mt-4' : 'mt-3'">
              <b-col cols="12">
                <b-row>
                  <template v-for="(method, i) of paymentMethods">
                    <b-col :key="'payment-method' + i" cols="12" class="mt-1">
                      <timezone-check-box
                        :row="method"
                        :title="method.title"
                        @on:check="onCheck"
                      />
                    </b-col>
                  </template>
                </b-row>
                <b-row
                  v-if="isCheckedInstalment"
                  class="mt-2"
                >
                  <b-col class="d-inline-flex">
                    <label class="niche-field-name-class mt-1">
                      {{ $t('components.custom.playground.new.step-2.timezone.instalment-percentage') }}
                    </label>
                    <d-text-field
                      v-model.number="block.instalmentPercentage"
                      min="0"
                      max="100"
                      size="sm"
                      type="number"
                      class-name="background-light-blue-inputs ml-1 text-center"
                      error-text="validation.required"
                    />
                    <label class="niche-field-name-class ml-2 mt-1"> % </label>
                  </b-col>
                  <b-col cols="12">
                    <div
                      v-if="isCheckedInstalment && (block.instalmentPercentage < 0 || block.instalmentPercentage > 100)"
                      class="invalid-feedback d-block"
                    >
                      {{ $t('components.custom.playground.new.step-2.timezone.percentage-validation') }}
                    </div>
                  </b-col>
                </b-row>
                <b-col
                  class="pl-0"
                  cols="12"
                >
                  <div
                    v-if="!selectedPaymentMethods"
                    class="invalid-feedback d-block"
                  >
                    {{ $t('validation.selection-required') }}
                  </div>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {getClubPlayground} from "@api/doinsport/services/playground/playground.api";
import {MULTIPLE_BOOKING_TYPE} from "@/classes/doinsport/Playground";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  components: {
    TimezoneCheckBox: () => import('@custom/playground/new/step-2/TimezoneCheckBox')
  },
  props: {
    block: {
      type: Object,
      default: this,
    },
    model: {
      type: Object,
      default: this,
    }
  },
  data: () => ({
    validation: require('@validation/entities/doinsport/Block.json'),
    paymentMethods: [],
    playground: null,
    startAtvalid: true,
    endAtvalid: true,
    endAtvalidGreater: true,
    selectedPaymentMethods: true,
  }),
  computed: {
    isCheckedInstalment () {
      const findInBlock = this.block.paymentMethods.find((el) => el === 'instalment');

      return isNotUndefinedAndNotNull(findInBlock);
    },
    hasBookingMultipleType () {
      if (this.playground) {
        return this.playground.bookingType === MULTIPLE_BOOKING_TYPE;
      }

      return null;
    },
    userClientStepBookingDuration() {
      return this.$store.getters["blocks/getBlocks"].userClientStepBookingDuration;
    },
    userClubStepBookingDuration() {
      return this.$store.getters["blocks/getBlocks"].userClubStepBookingDuration;
    }
  },
  methods: {
    checkEndAtGreaterThanStartAt() {
      if (parseInt(this.block.endAt.HH) < parseInt(this.block.startAt.HH)) {
        return false;
      } else if (parseInt(this.block.endAt.HH) === parseInt(this.block.startAt.HH) && parseInt(this.block.endAt.mm) <= parseInt(this.block.startAt.mm)) {
        return false;
      } else {
        return true;
      }
    },
    onCheck(row) {
      const findInBlock = this.block.paymentMethods.find((el) => el === row.value);

      if ("undefined" === typeof findInBlock) {
        this.selectedPaymentMethods = true;
        this.block.paymentMethods.push(row.value);
      } else {
        const selectedIndexBlock = this.block.paymentMethods.indexOf(findInBlock);
        this.block.paymentMethods.splice(selectedIndexBlock, 1);
      }
    },
    loadPlayground () {
      getClubPlayground(this.$route.params.id)
        .then((response) => {
          this.playground = response.data;
        })
      ;
    }
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:step-2-new-timezone-modal:validate');
    } catch (e) {

    }
  },
  created () {
    this.loadPlayground();
  },
  mounted() {
    this.$bus.$on('on:step-2-new-timezone-modal:validate', (validation) => {
      this.startAtvalid = validation.startAt;
      this.endAtvalid = validation.endAt;
      this.selectedPaymentMethods = "undefined" !== typeof this.paymentMethods.find(el => el.active === true);

      try {
        this.$refs.nameObserver.validate().then((valid) => {
          if (valid && this.startAtvalid && this.endAtvalid && this.selectedPaymentMethods) {
            if (this.isCheckedInstalment) {
              if (this.block.instalmentPercentage >= 0 && this.block.instalmentPercentage <= 100) {
                this.$bus.$emit('on:step-2-new-timezone-modal:validated');
              }
            } else {
              this.$bus.$emit('on:step-2-new-timezone-modal:validated');
            }
          }
        });
      } catch (e) {
      }

    });
    this.paymentMethods = [
      {
        title: this.$t('components.custom.playground.new.step-2.timezone.full'),
        active: false,
        value: 'complete'
      },
      {
        title: this.$t('components.custom.playground.new.step-2.timezone.by-player'),
        active: false,
        value: 'per_participant'
      },
      {
        title: this.$t('components.custom.playground.new.step-2.timezone.on-the-spot'),
        active: false,
        value: 'on_the_spot'
      },
      {
        title: this.$t('components.custom.playground.new.step-2.timezone.bank-imprint'),
        active: false,
        value: 'deposit'
      },
      {
        title: this.$t('components.custom.playground.new.step-2.timezone.instalment'),
        active: false,
        value: 'instalment'
      },
    ];
    if (this.model) {
      for (let method of this.paymentMethods) {
        const findInBlock = this.model.paymentMethods.find(el => el === method.value);
        if ("undefined" !== typeof findInBlock) {
          method.active = true;
          this.block.paymentMethods.push(method.value);
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.timezone-form-title {
  font: normal normal 500 18px Avenir;
  letter-spacing: 0px;
  color: #005873;
  opacity: 1;
}

.clock-class {
  font-size: 20px;
  color: #0c5460
}

/deep/ .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #0B2772;
  color: #fff;
  border: unset;
}
</style>
<style>
.vue__time-picker .dropdown ul.apms, .vue__time-picker .dropdown ul.minutes, .vue__time-picker .dropdown ul.seconds {
  border: unset;
}
</style>
